import Swiper, {
    Autoplay,
    EffectFade,
    Navigation,
    Pagination,
    Thumbs,
} from 'swiper';

import Helpers from '/src/global/js/helpers/helpers.js';

Swiper.use([Navigation, EffectFade, Autoplay, Pagination, Thumbs]);
function swiperInit() {
    const allSwiperBlocks = document.querySelectorAll('[data-swiper]');

    // exit function if there isn't any slider
    if (allSwiperBlocks.length === 0) {
        return null;
    }

    allSwiperBlocks.forEach(function (block) {
        const buttonNext = block.querySelector('.js-swiper-btn-next');
        const buttonPrev = block.querySelector('.js-swiper-btn-prev');
        const pagination = block.querySelector('.js-swiper-pagination');
        let thumbnailSlider = null;

        const params = Helpers.getJsonFromAttr(block.dataset.swiper);

        // check for thumbnail Slider
        if (Object.hasOwn(params, 'thumbs') && 'el' in params.thumbs) {
            thumbnailSlider = document.getElementById(params.thumbs.el);

            if (Object.hasOwn(thumbnailSlider, 'swiper')) {
                params.thumbs.swiper = thumbnailSlider.swiper;
            }
        }

        const swiperParams = {
            loop: false,
            speed: 600,
            autoplay: false,
            slidesPerView: 1,
            spaceBetween: 32,
            navigation: {
                prevEl: buttonPrev,
                nextEl: buttonNext,
            },
            pagination: {
                el: pagination,
                type: 'bullets',
                clickable: true,
            },
            slideChange: function (swiper) {},

            // override default settings
            ...params,
        };

        const swiper = new Swiper(block, swiperParams);

        swiper.on('slideChange', function (e) {
            var imageLoading = swiper.el.querySelector(
                '[data-swiper-slide-index="' +
                    swiper.realIndex +
                    '"] img.lazyloading',
            );

            if (imageLoading) {
                imageLoading.classList.remove('lazyloading');
                imageLoading.classList.add('lazyloaded');
            }
        });

        // Change current Slide number for slider which has .js-count-slides
        swiper.on('slideChangeTransitionEnd', function (swiper) {
            const countSlidesEl =
                swiper.$el[0].querySelector('.js-count-slides');
            const numberOfSlides = swiper.$el[0].querySelectorAll(
                '.swiper-slide:not(.swiper-slide-duplicate)',
            );
            if (countSlidesEl) {
                countSlidesEl.innerHTML =
                    swiper.realIndex + 1 + ' / ' + numberOfSlides.length;
            }
        });
    });
}

export default swiperInit;
