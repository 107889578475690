import Helpers from '/src/global/js/helpers/helpers.js';

// This function creates a tab panel
export default () => {
    $('[data-module="tabsA1"]').each(function (index, element) {
        // Get the jQuery object for the element.
        const module = $(element);

        // Get the jQuery objects for the tab buttons and tab contents.
        const buttons = module.find('.js-tab-a-button');
        const contents = module.find('.js-tab-a-content');

        // Get the JSON object of parameters from the data-params attribute.
        const params = Helpers.getJsonFromAttr(element.dataset.params);

        //
        let activeTabID = '';

        /**
         * Callback function for the Button Click Event
         * @param e
         * @return {void}
         */
        const clickButtonEvent = function (e) {
            // Get the jQuery object of the button that was clicked
            const button = $(e.currentTarget);

            // Get the id of clicked tab
            const tabId = button.data('id');

            // If the tabId is not defined, return
            if (!tabId) {
                return;
            }

            // Save active Tab ID
            activeTabID = tabId;

            // If the clicked button already has the active class, do nothing
            if (button.hasClass(params.active_button_classes)) {
                return;
            }

            closeTabs();
            openTab();
        };

        /**
         * Process click for change Tab outside the Module
         * @param e
         */
        const outsideClickEvent = function (e) {
            // Get the jQuery object of the target that was clicked
            const target = $(e.currentTarget);

            // Get the id of clicked tab
            const tabId = target.data('tab-trigger');

            module.find(`[data-id='${tabId}']`).trigger('click');
        };

        /**
         * This function removes the active class from all buttons and hides all tab contents
         * @return {void}
         */
        const closeTabs = function () {
            buttons.removeClass(params.active_button_classes);
            contents.slideUp();
        };

        /**
         * This function adds the active class to a button and shows the corresponding tab content
         * @return {void}
         */
        const openTab = function () {
            // Add the active class to the button
            getActiveBtn().addClass(params.active_button_classes);

            // Show the tab content with the given id
            getActiveContent().slideDown();
        };

        /**
         * Return the active Tab Button
         * @return {*|jQuery}
         */
        const getActiveBtn = function () {
            return module.find(`[data-id="${activeTabID}"]`);
        };

        /**
         * Return the active Tab Content
         * @return {*|jQuery}
         */
        const getActiveContent = function () {
            return module.find('#' + activeTabID);
        };

        // Registers Events
        (function () {
            // Register Click Event for the Buttons
            buttons.on('click', clickButtonEvent);
            // Register Click Event for the Buttons outside the Module
            $('[data-tab-trigger]').on('click', outsideClickEvent);
        })();
    });
};
