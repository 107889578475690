function hideBlocksOnFormSubmit() {
    // Add an event listener to the document to capture form submissions
    document.addEventListener('submit', function (event) {
        if (event.target.classList.contains('hs-form')) {
            let parent = event.target.closest('.o-call-to-action-a-1');

            if (parent) {
                let textWrapper = parent.querySelector('.js-text-wrapper');

                if (textWrapper) {
                    textWrapper.style.display = 'none';
                }
            }
        }
    });
}

export default hideBlocksOnFormSubmit;
