import MicroModal from 'micromodal';

export default () => {
    MicroModal.init({
        onShow: function (modal) {
            document.body.classList.add('l-body--modal-open');

            // update Swiper sliders because when modal is closed the width/height is calculated wrong
            modal.querySelectorAll('.swiper-container').forEach(function (el) {
                el.swiper.update();
            });

            // update lightGallery because when modal is closed the width/height is calculated wrong
            modal
                .querySelectorAll('[data-module="galleryB"]')
                .forEach(function (el) {
                    // el.refresh();
                });
        },
        onClose: () => document.body.classList.remove('l-body--modal-open'),
        disableScroll: true,
        awaitOpenAnimation: false,
        awaitCloseAnimation: true,
    });
};
