import '../../../global/js/vendors/fullpage/scrolloverflow.min.js';
import '../../../global/js/vendors/fullpage/fullpage.scrollHorizontally.min';
import '../../../global/js/vendors/fullpage/fullpage.parallax.min.js';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import fullpage from '../../../global/js/vendors/fullpage/fullpage.extensions.min';
import SplitText from '../../../global/js/vendors/gsap-splittext/splittext';

function fullpageSections() {
    // Container within which all the fullpage sections are located
    const fullpageContainer = document.querySelector('[data-type="fullpage"]');
    // Selector of fullpage sections
    const fullpageSectionSelector = '.fullpage-section';
    // Fullpage object instance
    let fullpageInstance = null;
    if (!fullpageContainer) {
        return;
    }
    // Fullpage sections with height 100vh
    let fullHeightSections = fullpageContainer.querySelectorAll(
        `${fullpageSectionSelector}--height-full`,
    );
    if (fullHeightSections.length < 1) {
        return;
    }

    // Scroll-to-next buttons
    const scrollButtons = fullpageContainer.querySelectorAll(
        '[data-role="scroll-to-next"]',
    );
    scrollButtons.forEach((button) => {
        button.addEventListener('click', (event) => {
            event.preventDefault();

            if (fullpageInstance) {
                fullpageInstance.moveSectionDown();
            }
        });
    });

    /**
     * Handles the section change event.
     *
     * @param {object} origin - The origin section.
     * @param {object} destination - The destination section.
     * @param {string} direction - The scroll direction.
     */
    const afterLoad = (origin, destination, direction) => {
        const title = destination.item.querySelector(
            '.m-fullpage-section__title',
        );
        const pretitle = destination.item.querySelector(
            '.m-fullpage-section__pretitle',
        );
        const subtitleContent = destination.item.querySelector(
            '.m-fullpage-section__text',
        );
        const buttonsRepeater = destination.item.querySelector(
            '.m-fullpage-section__buttons-repeater',
        );

        const tl = gsap.timeline();

        if (pretitle && !pretitleAnimationState[destination.index]) {
            pretitleAnimationState[destination.index] = true;

            tl.fromTo(
                pretitle,
                { autoAlpha: 0 },
                { duration: 0.1, autoAlpha: 1, ease: 'power4.inOut' },
                '-=0.4',
            );

            const splitTextPreTitle = new SplitText(pretitle, {
                tag: 'span',
                type: 'lines,chars',
                linesClass: 'm-fullpage-section__pretitle-container',
            });

            tl.from(
                splitTextPreTitle.chars,
                {
                    duration: 1,
                    yPercent: 100,
                    stagger: {
                        amount: 0.6,
                    },
                    ease: 'power4.inOut',
                },
                '<',
            );
        }

        if (title && !titleAnimationState[destination.index]) {
            titleAnimationState[destination.index] = true;

            tl.fromTo(
                title,
                { autoAlpha: 0 },
                { duration: 0.1, autoAlpha: 1, ease: 'power4.inOut' },
                '-=0.4',
            );

            // Check if the viewport width is under 900px
            const isViewportUnder900px = window.innerWidth < 900;

            // Animating words instead of chars to fix mobile issues
            if (isViewportUnder900px) {
                const splitTextTitle = new SplitText(title, {
                    tag: 'span',
                    type: 'words',
                    linesClass: 'm-fullpage-section__title-container',
                });

                tl.from(
                    splitTextTitle.words,
                    {
                        duration: 1.2,
                        yPercent: 100,
                        autoAlpha: 0,
                        stagger: {
                            amount: 0.6,
                        },
                        ease: 'power4.inOut',
                    },
                    '<',
                );
            } else {
                const splitTextTitle = new SplitText(title, {
                    tag: 'span',
                    type: 'lines,chars',
                    linesClass: 'm-fullpage-section__title-container',
                });

                tl.from(
                    splitTextTitle.chars,
                    {
                        duration: 1.2,
                        yPercent: 100,
                        stagger: {
                            amount: 0.6,
                        },
                        ease: 'power4.inOut',
                    },
                    '<',
                );
            }
        }

        if (subtitleContent && !subtitleAnimationState[destination.index]) {
            subtitleAnimationState[destination.index] = true;

            tl.fromTo(
                subtitleContent,
                { opacity: 0, yPercent: 100 },
                {
                    duration: 0.6,
                    opacity: 1,
                    yPercent: 0,
                    ease: 'power4.inOut',
                },
                '<+=0.4',
            );
        }

        if (buttonsRepeater && !buttonsRepeaterState[destination.index]) {
            buttonsRepeaterState[destination.index] = true;

            tl.fromTo(
                buttonsRepeater,
                { opacity: 0 },
                {
                    duration: 0.6,
                    opacity: 1,
                    ease: 'power4.inOut',
                },
                '<+=0.4',
            );
        }
    };

    /**
     * Handles slide leave event.
     *
     * @param {object} origin - The origin section.
     * @param {object} destination - The destination section.
     * @param {string} direction - The scroll direction.
     */
    const onSlideLeave = (origin, destination, direction) => {
        // Reset animation state when leaving a slide.
        pretitleAnimationState[origin.index] = false;
        titleAnimationState[origin.index] = false;
        subtitleAnimationState[origin.index] = false;
        buttonsRepeaterState[origin.index] = false;
    };

    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);

    const pretitleAnimationState = {};
    const titleAnimationState = {};
    const subtitleAnimationState = {};
    const buttonsRepeaterState = {};

    let lastSectionEntered = false;
    const sections = document.querySelectorAll('.fullpage-section');

    // Init
    fullpageInit();

    function fullpageInit() {
        if (fullpageContainer) {
            fullpageInstance = new fullpage(fullpageContainer, {
                scrollingSpeed: 1000,
                parallax: true,
                parallaxKey:
                    'RXZkM0JsYm1kcGJtVXVZMjl0R01fUTJPY0dGeVlXeHNZWGc9dlcw', // eslint-disable-line
                licenseKey: '3LYD7-9IRE8-JL926-HH896-OUWYN',
                scrollOverflowResetKey:
                    'OHhkM0JsYm1kcGJtVXVZMjl0Q0NfNnRmYzJOeWIyeHNUM1psY21ac2IzZFNaWE5sZEE9PTRLbg==', // eslint-disable-line
                scrollOverflowReset: true,
                scrollOverflow: true,
                sectionSelector: fullpageSectionSelector,
                fitToSection: false,
                lazyLoading: false,
                afterLoad,
                onSlideLeave,
                onLeave: function (origin, destination, direction, trigger) {
                    // Lazy loaded elements
                    const lazyLoadedElements =
                        destination.item.querySelectorAll(
                            '.aos-init:not(.aos-animate)',
                        );
                    // Show them if they exist within the current section
                    lazyLoadedElements.forEach((element) => {
                        element.classList.add('aos-animate');
                    });
                },
            });
        }
    }
}

export default fullpageSections;
