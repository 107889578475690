/**
 * Handles the translation widget functionality.
 */
function widgetTranslation() {
    // Language Widget Blocks
    const widget = '.js-widget-translation';
    const toggler = '.js-widget-translation-toggler';
    const headerBtn = '.js-widget-translation-header';

    /**
     * Toggle the active class on the widget when the toggler is clicked.
     */
    $(toggler).on('click', function () {
        const widgetObj = $(this).closest(widget);

        if (widgetObj.hasClass('active')) {
            widgetObj.removeClass('active');
        } else {
            widgetObj.addClass('active');
        }
    });

    /**
     * Trigger the click event on the toggler when the header button is clicked.
     * @param {Event} e - The click event.
     */
    $(headerBtn).on('click', function (e) {
        e.preventDefault();
        $(toggler).trigger('click');
    });

    /**
     * Remove the active class from the widget if the click is outside the widget or header button.
     * @param {Event} e - The click event.
     */
    $(document).on('click', function (e) {
        if (
            !$(e.target).closest(widget).length &&
            $(widget).hasClass('active') &&
            !$(e.target).closest(headerBtn).length
        ) {
            $(widget).removeClass('active');
        }
    });

    const languageSwitchersBlocks = document.querySelectorAll(
        `.js-google-translate-switcher`,
    );

    if (languageSwitchersBlocks.length) {
        // Looping through all of them (we can have several of them)
        [...languageSwitchersBlocks].map((languageSwitcherBlock) => {
            /**
             * Handle click event on the language switcher block.
             * @param {Event} event - The click event.
             */
            languageSwitcherBlock.addEventListener('click', (event) => {
                // Click Target
                const target = event.target;
                // Language switcher
                const button = target.closest('a');
                // If we click out of button - do nothing
                if (!button) {
                    return;
                }

                // Preventing default behaviour
                event.preventDefault();

                // Language value of this button
                const chosenLanguage = button.getAttribute('data-href');
                if (!chosenLanguage) {
                    return;
                }

                // Google Translate Select
                const googleTranslateSelect =
                    document.querySelector('.goog-te-combo');
                if (!googleTranslateSelect) {
                    return;
                }

                // Set the chosen value
                googleTranslateSelect.value = chosenLanguage;
                // Dispatching event and allow google to translate the page
                googleTranslateSelect.dispatchEvent(new Event('change'));
            });
        });
    }
}

export default widgetTranslation;
