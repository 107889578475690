import Swiper from 'swiper';

function swiperHero() {
    const componentClass = 'o-hero-4';
    const sliderBlock = document.querySelector(
        `.${componentClass}__container.swiper-container`,
    );
    const paginationBlock = document.querySelector(
        '[data-role="hero-4-pagination"]',
    );
    if (!sliderBlock) {
        return;
    }

    const slides = {};
    // Find all pagination items if available, otherwise use an empty array
    const paginationItems = paginationBlock
        ? paginationBlock.querySelectorAll('[data-slide]')
        : [];
    // Create a new Swiper instance with custom settings
    const swiper = new Swiper(sliderBlock, {
        // Disable automatic slide switching
        autoplay: false,
        // Disable swiping gesture
        allowTouchMove: false,
        effect: 'fade',
        // Set initial slide
        initialSlide: 0,
        init: false,
    });
    // Add an event listener to the pagination block
    if (paginationBlock) {
        paginationBlock.addEventListener('click', (event) => {
            // Find the clicked pagination item
            const paginationItem = event.target.closest('[data-slide]');
            if (!paginationItem) {
                return;
            }
            // Get the slide index from the clicked item's data attribute
            let slideIndex = paginationItem.dataset.slide;
            if (!slideIndex) {
                return;
            }
            // Convert the slide index to an integer
            slideIndex = parseInt(slideIndex, 10);
            // Slide the swiper to the selected index
            swiper.slideTo(slideIndex);
            // Set the active class on pagination items
            setPaginationItemActiveClass();
        });
        // Set active class on pagination items when swiper initialization and slide change occur
        swiper.on('init', setPaginationItemActiveClass);
        swiper.on('slideChange', setPaginationItemActiveClass);
    }
    // Initialize slides and pagination
    initSlides();
    initPagination();
    // Initialize the swiper instance
    swiper.init();

    // Function to initialize slide objects
    function initSlides() {
        sliderBlock
            .querySelectorAll('.swiper-slide')
            .forEach((slide, index) => {
                slides[index] = {
                    name: slide.dataset.name,
                    el: slide,
                };
            });
    }

    // Function to initialize pagination with slide names
    function initPagination() {
        if (!paginationBlock || Object.keys(slides).length < 1) {
            return;
        }
        paginationItems.forEach((item, index) => {
            const itemIndex = item.dataset.slide
                ? parseInt(item.dataset.slide, 10)
                : null;
            if (slides[itemIndex] !== undefined) {
                item.innerHTML = slides[itemIndex].name;
            }
        });
        paginationBlock.classList.remove('hidden');
    }

    // Function to set active class on pagination items
    function setPaginationItemActiveClass() {
        const slideIndex = swiper.activeIndex;
        if (paginationBlock) {
            paginationItems.forEach((item) => {
                const itemIndex = item.dataset.slide
                    ? parseInt(item.dataset.slide)
                    : null;
                if (itemIndex === slideIndex) {
                    item.classList.add(
                        'active',
                        'bg-brand-color-4',
                        'text-body-light',
                    );
                } else {
                    item.classList.remove(
                        'active',
                        'bg-brand-color-4',
                        'text-body-light',
                    );
                }
            });
        }
    }
}

export default swiperHero;
