import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

import Helpers from '/src/global/js/helpers/helpers.js';

function galleryA() {
    const lgContainers = document.querySelectorAll('[data-module="galleryB"]');
    lgContainers.forEach(function (lgContainer) {
        const dataLightGallery = Helpers.getJsonFromAttr(
            lgContainer.dataset.lightgallery,
        );
        if (!(dataLightGallery instanceof Object)) {
            return;
        }

        lgContainer.addEventListener('click', (event) => {
            const target = event.target.closest('.lg-maximize');
            if (!target) {
                return;
            }
            const parent = target.closest('.lg-inline');
            if (!parent) {
                document.body.classList.add('lightGallery--opened');
                return;
            }
            document.body.classList.remove('lightGallery--opened');
        });

        const inlineGallery = lightGallery(lgContainer, {
            plugins: [lgThumbnail],
            container: lgContainer,
            dynamic: true,
            dynamicEl: [],
            // thumbWidth: '215',
            // thumbHeight: '150',
            closable: false,
            hash: false,
            showMaximizeIcon: true,
            appendSubHtmlTo: '.lg-item',
            download: false,
            // slideDelay: 400,

            // override default settings
            ...dataLightGallery,
        });

        //
        lgContainer['galleryID'] = inlineGallery;

        // Since we are using dynamic mode, we need to programmatically open lightGallery
        inlineGallery.openGallery();
    });
}

export default galleryA;
