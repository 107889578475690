import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
    const stickySidebar = document.querySelector(
        '[data-module="sidebarPlanA1"]',
    );

    // return if element don't exists
    if (!stickySidebar) {
        return;
    }

    /**
     * Sticky Sidebar functions
     **/
    (function () {
        // Selectors of elements to add to Sidebar offset top
        const selectorsToOffset = ['.js-header', '#wpadminbar'];

        // Register scroll event for the Sidebar
        document.addEventListener('scroll', function () {
            if (window.matchMedia('(min-width: 1024px)').matches) {
                stickySidebar.style.top = getSidebarOffsetTop() + 'px';
                return;
            }
            stickySidebar.style.top = '';
        });

        /**
         * Get number of pixels to add to Sidebar offset top
         * @return {number}
         */
        function getSidebarOffsetTop() {
            let offset = 30;

            for (let i = 0; i < selectorsToOffset.length; i++) {
                const el = document.querySelector(selectorsToOffset[i]);
                let elHeight = 0;

                if (el) {
                    elHeight = getVisibleHeight(el);
                }
                offset += elHeight;
            }

            return offset;
        }

        /**
         * Get the height of the visible part on the screen of the element
         * @param element HTML object
         * @return {number}
         */
        function getVisibleHeight(element) {
            const rect = element.getBoundingClientRect();

            // Check if the element is completely out of the viewport:
            if (rect.bottom < 0 || rect.top > window.innerHeight) {
                return 0;
            }

            // If the element is partially visible:
            const top = Math.max(0, rect.top);
            const bottom = Math.min(window.innerHeight, rect.bottom);

            return bottom - top;
        }
    })();

    /**
     * Make Buttons sticky
     **/
    (function () {
        gsap.registerPlugin(ScrollTrigger);

        const st = ScrollTrigger.create({
            trigger: '.js-buttons-static',
            start: 'bottom top',
            onEnter: () => {
                const fixedButtons = getStickyButtons();
                if (!fixedButtons) {
                    return;
                }
                fixedButtons.classList.add('js-buttons-sticky--pinned');
            },
            onLeaveBack: () => {
                const fixedButtons = getStickyButtons();
                if (!fixedButtons) {
                    return;
                }
                fixedButtons.classList.remove('js-buttons-sticky--pinned');
            },
        });

        function getStickyButtons() {
            return stickySidebar.querySelector('.js-buttons-sticky');
        }

        // Force the ScrollTrigger to update immediately after initialization
        st.update();
    })();
};
