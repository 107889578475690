/**
 * @file fullscreenAccordionA.js
 * @description Handles the functionality of a fullscreen accordion component with hover and click/tap interactions.
 * @module fullscreenAccordionA
 * @author Dreamers of Day
 */

/**
 * Initializes the fullscreen accordion functionality.
 * Adds event listeners to panels for hover (desktop) and click/tap (mobile) interactions.
 */
function fullscreenAccordionA() {
    // Maximum width to consider the device as mobile
    const MOBILE_MAX_WIDTH = 1024;

    // Query all accordion panels
    const panels = document.querySelectorAll(
        '.o-fullscreen-accordion-a-1__repeater-item',
    );

    // CSS class to denote an active panel
    const panelActiveClass =
        'o-fullscreen-accordion-a-1__repeater-item--active';

    // Keeps track of the currently active panel
    let activePanel = null;

    // Iterate over each panel to add event listeners
    panels.forEach((panel) => {
        const video = panel.querySelector('.m-media-a__video-tag');
        let playPromise = null;

        // Add event listener for hover on desktop
        panel.addEventListener('mouseover', () => {
            if (window.innerWidth > MOBILE_MAX_WIDTH) {
                setActivePanel(panel);
                handleVideoPlayPause(panel, video, playPromise);
            }
        });

        // Add event listener for click/tap on mobile
        panel.addEventListener('click', () => {
            setActivePanel(panel);
            if (window.innerWidth <= MOBILE_MAX_WIDTH) {
                handleVideoPlayPause(panel, video, playPromise);
            }
        });

        // Check if the panel has the active class initially
        if (panel.classList.contains(panelActiveClass) && video) {
            playPromise = video.play();
        }
    });

    /**
     * Sets the active panel, removing active classes from others.
     * @param {Element} panel - The panel to set as active.
     */
    function setActivePanel(panel) {
        if (activePanel !== panel) {
            removeActiveClasses();
            panel.classList.add(panelActiveClass);
            activePanel = panel;
        }
    }

    /**
     * Handles the video play/pause logic based on the active class.
     * @param {Element} panel - The panel containing the video.
     * @param {HTMLVideoElement} video - The video element to play/pause.
     * @param {Promise} playPromise - The promise returned from the play method.
     */
    function handleVideoPlayPause(panel, video, playPromise) {
        if (video) {
            if (panel.classList.contains(panelActiveClass)) {
                playPromise = video.play();
            } else {
                if (playPromise !== null) {
                    playPromise.then(() => video.pause()).catch(() => {});
                } else {
                    video.pause();
                }
            }
        }
    }

    /**
     * Removes the active class from all panels.
     * Pauses any videos in the panels.
     */
    function removeActiveClasses() {
        panels.forEach((panel) => {
            panel.classList.remove(panelActiveClass);
            const video = panel.querySelector('.m-media-a__video-tag');
            if (video) {
                video.pause(); // Pause video
            }
        });
    }

    // Handle resizing to maintain active panel state
    window.addEventListener('resize', () => {
        // Ensure the active panel keeps its active class on resize
        if (activePanel) {
            removeActiveClasses();
            activePanel.classList.add(panelActiveClass);
        }
    });
}

export default fullscreenAccordionA;
