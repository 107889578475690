/**
 * Hamburger
 *
 * Runs the functionality of clicking the hamburger icon in the top nav.
 */

/**
 * Toggle the hamburger functionality.
 */
export default () => {
    const selector = '.js-hamburger';
    const activeClass = 'is-active';
    const bodyActiveClass = 'h-scroll-lock nav-active';
    const headerActiveClass = 'o-header--hamburger-active';
    const navMenu = '.js-slide-out';
    const navMenuActiveClass = 'm-slide-out--active';
    const headerSearchButton = 'o-header-1__button-search';
    const searchBar = 'm-search-bar';
    const nav = '.m-slide-out__nav';

    /**
     * Toggle the hamburger menu.
     */
    function toggleMenu() {
        $(selector).toggleClass(activeClass);
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);
        $(navMenu).toggleClass(navMenuActiveClass);
        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }
    }

    /**
     * Click event handler for the hamburger icon.
     * @param {Event} e - The click event.
     */
    $(selector).click((e) => {
        e.preventDefault();
        toggleMenu();
    });

    /**
     * Mouseup event handler to close the hamburger menu when clicking outside.
     * @param {Event} e - The mouseup event.
     */
    $(document).on('mouseup', (e) => {
        const hamburger = $(selector);
        const isHamburgerActive = hamburger.hasClass(activeClass);
        // Check if the clicked element is not inside $(nav) and the hamburger is active
        if (
            isHamburgerActive &&
            !$(nav).has(e.target).length &&
            !e.target.closest(selector)
        ) {
            toggleMenu();
        }
    });

    /**
     * Resize event handler to close the hamburger menu when the window width exceeds a certain threshold.
     */
    $(window).on('resize', () => {
        const windowWidth = $(window).width();
        const isHamburgerActive = $(selector).hasClass(activeClass);

        if (windowWidth >= 1140 && isHamburgerActive) {
            toggleMenu();
        }
    });
};
